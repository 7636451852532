
.reply_form_main{
    padding: 5px;
    border: solid 2px white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.reply_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20vw;
}
.reply_form>div{
    margin:5px;
}
.reply_form_button_box>div{
    background-color: rgb(176, 176, 176);
    padding: 5px;
    overflow: hidden;
    border-radius: 5px;
}
.reply_form_button_box>div:hover{
    background-color: rgb(86, 86, 86);
    cursor: pointer;
}
#reply_input{
    width:15vw;
}
#reply_input_label{
    align-items: center;
    display: flex;
}
