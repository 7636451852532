.picture_view{
    display: flex;
    align-items: center;
    justify-content: center;
}
.preview_div{
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    padding: 10px;
}
.preview_image_display{
    grid-column: 2;
    max-height: 400px;
    overflow: hidden;
}
.landing_image{
    max-width: 100%;

}
.prev_arrow{
    transition: all .5s;
    font-size: 60px;
    min-width: 90px;
    color: #8aac1a ;
}
.prev_arrow:hover{
    font-size: 90px;
    transition: all .5s;
    color: #8aac1a;
}
.preview_previous{
    grid-column: 1;
}
.preview_next{
    grid-column: 3;
}
.post_image{
    max-width: 20vw;
}