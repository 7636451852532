.preview_div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.preview_image_display{
    display: flex;
    align-items: center;
    justify-content: center;
}
.prev_arrow{
    font-size: 60px;
}
