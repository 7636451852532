.postform_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px;
}
.text_box > label{
    display: flex;
    flex-direction: column;
}
.post_form_button_box>div{
    background-color: rgb(176, 176, 176);
    padding: 5px;
    overflow: hidden;
    border-radius: 5px;
}
.post_form_button_box>div:hover{
    background-color: rgb(86, 86, 86);
    cursor: pointer;
}
.post_form>div{
    margin: 10px;
}
.delete_image_div{
    background-color: red;
    border-radius: 5px;
}
.this_image_list{
    padding: 5px;
    max-height: 70px;
    overflow-y: auto;
}
.image_list_title{
    border-bottom: 1px solid black;
}
.image_item{
    margin: 5px;
}
.image_name{
    border: 1px solid black;
    border-radius: 5px;
}
