



.reply_feed{
    margin-top: 2px;
    padding: 4px;
    font-size: 10px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.reply_feed_title:hover{
    transition: 1s;
    margin: 2px;
}
.create_reply_box{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;

}
.post_main{
    background-color:#f6f6f6;
    padding: 2px;
    color: black;
    max-width: 100%;
}
.post_content{
    background-color: white;
    border: solid 1px white;
    color: black;
    font-size: 14px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    display: flex;
    align-items: center;
    flex-direction: column;

}
.post_title{
    width:99%;
    background-color: #8aac1a;
    font-size: 17px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}
.title_div{
    width: 60%;
    color: white;
}
.post_date{
    margin: 5px;
}
.post_button_box{
    position: relative;
    display: flex;

    font-size: 10px;
    margin: 5px;
}
.post_edit{
    grid-column: 1;
}
.post_delete{
    grid-column: 3;
}
.post_tag_list{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
}
.post_tag_button{
    border: solid 1px white;
    border-radius: 5px;
    width: min-content;
    padding: 5px;
    font-size: 11px;
    transition: .5s;

}
.post_main{
    height: min-content;
    padding: 5px;
}
.donate_button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.post_body{
    background-color: white;
    color: black;
    display: flex;
    padding: 5px;
    margin: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    margin-bottom: 20px;
    font-size: 15px;
    min-width: 30vw;
    max-width: 50vw;
}
.true_tag{
    background-color: rgb(245, 230, 171);
}
.false_tag{
    background-color: rgb(92, 81, 35);
}
