.main_mid{
    grid-column: 2;
    width: auto;
    background-color: rgb(206, 213, 214);
    border: solid 1px black;
}
.landing_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.official_tabs{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.tab{
    border: solid 1px rgb(43, 43, 43);
    padding: 3px;
    background-color: rgb(95, 97, 122);
    padding: 5px;
}
.mains{
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: auto;
    margin: 5px;
}
.landing_upcoming{
    margin: 15px;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: rgb(255, 255, 255);
}
.landing_upcoming>div{
    margin: 5px;
}
.landing_upcoming_title{
    background-color: #8aac1a;
    padding: 10px;
    width: 85%;
    color: white;
}
.landing_new{
    margin: 5px
}
.home_image{
    max-width: 50vw;
}
