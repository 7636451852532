.transaction_main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: -1;

}
.transaction_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-top: 50px;
}
.donation_sub_menu{
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.266);
}
.donation_list_categories{
    /* display: flex;
    flex-direction: column;
    width: 150px;
    background-color: rgb(206, 213, 214);
    overflow: hidden;
    border-radius: 10px;
    margin: 5px;
    max-height: 80%;
    overflow-y: scroll; */
    /* width:100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    scrollbar-width: none;
    /* overflow-x: hidden; */
    margin: 5px;
}
.index_title{
    width: 100%;
    color: #8aac1a;
    border-bottom: 1px solid #8aac1a;
    font-size: 20px;
}
.donation_list_categories > .category{
    background-color: white;
    margin: 5px;
    /* width: 90%; */
    border-bottom: 1px solid black;
}
.donation_list_categories > .clickable:hover{
    transition: 1s;
    font-size: 20px;
}
.transaction_display{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    background-color: antiquewhite;
    margin: 5px;
}
.tran_half{
    display: flex;
    flex-direction: column;
}
.title_bar{
    background-color: #8aac1a;
    color: white;
    padding: 5px;
    width: 100%;
}
/* .a{
    align-items: start;
}
.b{
    align-items: end;
} */
.all_div{
    font-weight: bold;
    transition: 1s;
}
.pledge_div{
    font-weight: bold;
    transition: 1s;
}
.fulfilled_div{
    transition: 1s;
    font-weight: bold;

}
.post_name:hover{
    transition: 1s;
    color: #8aac1a;
}

.empty_donation_list{
    font-size: 20px;
    padding: 5px;
}
.hide{    
    height: 0px;
    transition: 1s;
}
.show{
    transition: 1s;
    height:30vh;
}
.menu_icon{
    font-size: 20px;
    color: #8aac1a;
}
.menu_box{
    scrollbar-width: none;
    height: 100%;
}