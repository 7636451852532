/* TODO Add site wide styles */
.main_app{
    /* display: grid;
    grid-template-columns: 1fr 3fr; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(0, 0, 0);
    z-index: -1;
}
.main_display{
    font-family: Arial, Helvetica, sans-serif;
    position: static;
    border-radius: 10px;
    width: 80%;
    z-index: 1;
}
body{
    background-color: rgb(255, 255, 255);
}

.clickable{
    cursor: pointer;
}
.error{
    color: red;
    align-self: center;
}
