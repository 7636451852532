.post_list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: auto;
    padding: 5px;
    }
.my_posts_list_title{
    background-color: white;
    color: #8aac1a;
    border-bottom: 1px solid #8aac1a;
    padding:1px;
}
.post_sub_menu{
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.266);   
}
.post_tab{
    transition: .5s;
    background-color: white;
    overflow: hidden;
    border-radius: 10px;
    padding: 5px;
    font-size: 14px;
    /* max-width: 10vw;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word; */
}
.post_tab:hover{
    transition: .5s;
    cursor: pointer;
    color: #8aac1a;
}
.my_posts_display{
    margin-right: 5px;
    margin-top: 45px;
    justify-self: center;
}
.my_posts_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.post_placeholder{
    margin: 5px;
    padding-right: 10px;
    width: 300px;
    font-size: 18px;
}
.empty_post_list{
    padding: 5px;
    margin: 5px;
}
.hide{    
    overflow-y: auto;
    height: 0px;
    transition: 1s;
}
.show{
    transition: 1s;
    overflow-y: auto;
    height:30vh;
}
.menu_icon{
    font-size: 20px;
    color: #8aac1a;
}