.menu{
    margin: 20px;
    border: solid 1px black;
    background-color: rgb(206, 213, 214);
    width:inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 0;
    gap:50px;
}
.menu_title{
    background-color: rgb(95, 97, 122);
    padding: 5px;
}
.menu > div{
    padding: 5px;
    font-size: 15px;
}
.menu >.clickable:hover{
    transition: 1s;
    font-size: 15px;
}
/* .menu_option{
    width:33%;
} */
