.feed_main{
    position: relative;
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
}
.feed_box{
    display: flex;
    background-color: rgb(255, 255, 255);
    flex-direction: column;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.266);
    border-radius: 3px;
    /* margin-top: 80px; */
    min-width: 30vw;
    width: 80vw;
}
.feed_title{
    /* background-color: #8aac1a; */
    border-bottom: 1px solid #8aac1a;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #8aac1a;
    font-size: 30px;
}
.feed_button_box{
    padding: 10px;
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    font-size: 23px;
    margin-top: 25px;
}
.sub_menu{
    z-index: 10;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255);
    /* border-radius: 10px; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 0px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.266);
    top: 0px;
    padding: 3px;

}
.sub_menu > .hides{
    height: 0px;
    width: 0px;
}
.sub_menu > .shows{
    transition: .3s;
    max-height: 60vh;
}
/* .feed_sub_menu{
    top:0px;
    
    position: fixed;
    display: flex;
    flex-direction: row;
    width: auto;
    max-height: 60vh;
    min-width: 15vw;
    background-color: rgb(255, 255, 255);
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    border-radius: 10px;
    margin: 15px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.266);
    overflow-y: auto;
    z-index: 2;
} 
*/
.feed_sub_menu > * {
z-index: 100;
}
.feed_list_display{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.feed_list_display > div{
    padding:  2px 5px;

}
.feed_desc{
    transition: 1s;
    background-color: white;
    overflow: hidden;
    border-radius: 10px;
    margin: 5px;
    font-size: 12px;
    /* max-width: 10vw; */
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}
.feed_desc{
 padding: 10px;
 transition: 1s;
 color: #8aac1a;
}

.feed_list_title{
    border-bottom: 1px solid #8aac1a;
    color: #8aac1a;
}
.feed_body{
    padding: 10px;
}
.menu_icon{
    margin: 5px;
    font-size: 20px;
    color: #8aac1a;
}
.menu_button{
    margin-top: 3px;
}
.menu_box{
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    transition: 1s;
}
.hides{
    width: 80px;
    height: 30px;
    scrollbar-width: none;
}
.shows{
    transition: 1s;
}
@media screen and (min-width: 451px) 
/* and (max-width: 1400px)  */
{
    .hides{    
        overflow-y: auto;
    }
    .shows{
        transition: 1s;
    }
}
/* @media screen and (min-width:1401px){
    .hides{    
        overflow-y: auto;
        transition: 1s;
    }
    .shows{
        transition: 1s;
        margin-right: 30em;
        overflow-y: auto;
    }
    .feed_sub_menu{
        top: 67em;
        right: -22.5em;
    }
} */
@media screen and (max-width: 450px){
    .hides{    
        overflow-y: auto;
    }
    .shows{
        overflow-y: auto;
    }
   
}


.feed_button_box_main{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: space-between;
}




/* .feed_main{
    position: relative;
    z-index: -1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;

}
.feed_box{
    display: flex;
    background-color: rgb(255, 255, 255);
    flex-direction: column;
    align-self: flex-start;
    overflow: hidden;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.266);
}
.feed_title{
    background-color: #8aac1a;
    padding:10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    color: white;
}
.feed_body > div{
    margin: 15px;
}
.feed_body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.feed_button_box{
    position: relative;
    display: inline-flex;
    height: fit-content;
    justify-content: space-between;
}
.feed_list_button_box{
    background-color: #8aac1a;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.feed_list_display{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

}
.feed_list_display > div{
    padding:  2px 5px;

}
.feed_desc{
    transition: 1s;
    background-color: white;
    overflow: hidden;
    border-radius: 10px;
    margin: 5px;
    font-size: 14px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}
.feed_desc:hover{
 padding: 10px;
 transition: 1s;
 color: #8aac1a;
}
.feed_sub_menu{
    position: sticky;
    display: flex;
    flex-direction: column;
    width: auto;
    min-width: 25vw;
    background-color: rgb(255, 255, 255);
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    border-radius: 10px;
    margin: 15px;
    max-height: 60%;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.266);
}
.feed_list_title{
    background-color: #8aac1a;
    color: white;
}
.empty_div{
    width:60vw;
} */
