.button{
    border: solid 3px rgb(255, 255, 255);
    background-color: #8aac1a;
    border-radius: 5px;
    overflow: hidden;
    color: white;
    width: fit-content;
    padding: 5px;
    margin: 2px;
    transition: 1s;
    text-align: center;
    z-index: 1;
}
.button>i{
    text-align: center;
}
.button:hover {
    transition: 1s;
    background-color: rgb(255, 255, 255);
    color:#8aac1a;
}
