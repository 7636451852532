.transaction_form_button_box{
    display: flex;
    align-items: center;
    justify-content: center;
}
.transaction_form_button_box>div{
    background-color: rgb(176, 176, 176);
    padding: 5px;
    overflow: hidden;
    border-radius: 5px;
    width: min-content;
}
.transaction_form_button_box>div:hover{
    background-color: rgb(86, 86, 86);
    cursor: pointer;
}
.transaction_form_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.transaction_form>div{

    margin: 5px;
}
.transaction_form_input_div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.trans_type_select{
    display: flex;
    align-items: center;
    justify-content: center;
}
