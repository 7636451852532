.feed_form>div{
    margin: 5px;
}
.feed_form_main{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.feed_input_label{
    margin:0px 5px;
}
.feed_input_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.feed_input_box{
    display: inline-flex;
    justify-content: space-between;
    margin: 5px;
}
.feed_form_button_box{
    display: flex;
    justify-self: center;
    align-self: center;
    align-content: center;
    justify-content: center;
}
.feed_form_button_box >div{
    background-color: rgb(176, 176, 176);
    padding: 5px;
    overflow: hidden;
    border-radius: 5px;
}
.feed_form_button_box >div:hover{
    background-color: rgb(86, 86, 86);
    cursor: pointer;
}
