.profile_main{
  align-self: flex-start;
}
.profile_button_div{
  position: relative;

}
.profile-dropdown {
  position: absolute;
  top:32px;
  left:-45px;
  color: #8aac1a;
  background-color: rgb(242, 242, 242);
  border: solid 1px rgb(162, 162, 162);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  z-index: 3;
  width: 100px;
}

.profile_button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  overflow: hidden;
  border-radius: 20px;
}
.log_out_button{
  background-color: rgb(176, 176, 176);
  padding: 5px;
  overflow: hidden;
  border-radius: 5px;
  text-align: center;
}
.log_out_button:hover{
  background-color: rgb(86, 86, 86);
  cursor: pointer;
}
.logged_out_menu{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 3;
}
.profile_button :hover{
  background-color: rgb(81, 81, 81);
}
.profile_button >i{
  padding: 5px;
}


.hidden {
  display: none;
}
.navbar {
  position: static;
  display: flex;
  flex-direction: column;
  z-index: 3;
  top: 0;
  left: 0;
}
.logo_nav{
  height: 50px;
}
.t_nav{
display: flex;
background-color: rgb(250, 250, 250);
align-items: center;
justify-content: center;
}
.b_nav{
  display: flex;
  flex-direction: row;
  background-color: #8aac1a;
  color: rgb(255, 255, 255);
  height: 40px;
  align-items: center;
  justify-content: center;
  gap: 10%;
  padding: 0px 10px;
}
.main_menu{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 5px;
}
.menu_option{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px;
  padding: 5px;
  transition: 1s;
}
.menu_option:hover{
  transition: 1s;
  color:#8aac1a;
  background-color: white;
}
.gap{
  width:7vw;
}
@media screen and (min-width: 501px) {
  .size_check {
    transition: .5s;
    font-size: 15px;
    text-align: center;
  }
}
@media screen and (max-width: 500px){
  .size_check {
    transition: .5s;
    font-size: 0px;
    text-align: center;
  }
}
