.about_main{
    border-top: 1px solid #8aac1a;
    border-bottom: 1px solid #8aac1a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.link_logo{
    width:2vw;
    margin: 5px;
}
.logo_box{
    display: flex;
}
