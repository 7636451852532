.reply_user{
    width:100%;
    display: block;
    border-bottom: 1px solid #8aac1a;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    text-align: center;
    font-size: 15px;
}
.reply_main{
    border: solid 1px white;
    border-radius: 5px;
    margin: 2px;
    font-size: 12px;
    color: black;
    padding:5px;
    overflow: hidden;
    width: 20vw;
    background-color: #8aac1a;
}
.reply_body{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
}
.button_box{
    display: flex;
    flex-direction: row;
    font-size: 10px;
    background-color: #8aac1a;
    justify-content: space-between;
}
.reply_message{
    padding: 5px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    background-color: white;
}
