.login_form_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.login_input_box{
    display: inline-flex;
    justify-content: space-between;
    margin: 5px;
}
.login_input_label{
    margin: 5px;
}
.login_input{
    margin: 5px;
}
.login_form{
    display: flex;
    flex-direction: column;
}
.login_button_box{
    align-self: center;
}
.demo_login{
    background-color: white;
    padding: 5px;
    margin: 5px;
    border: solid 1px black;
    text-align: center;
}
.demo_login:hover{
    background-color: rgb(128, 128, 128);
    cursor: pointer;
}
.login_button_box>div{
    background-color: rgb(176, 176, 176);
    padding: 5px;
    overflow: hidden;
    border-radius: 5px;
}
.login_button_box>div:hover{
    background-color: rgb(86, 86, 86);
    cursor: pointer;
}
